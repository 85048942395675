@use "sass:color";

$themes: (
  purple: (
    background: #F5F5FF,
    color: #5C32EC,
    color2: #5C32EC50,
    color3: #5C32EC90,
    secondary: #8B62FF,
    line: #C7D1FF,
    header1: #F1EEFF,
    header2: #FFF2FF,
    sel: #8B62FF,
    light: #ADFFFA,
    filter: contrast(40%) sepia(60%) saturate(300%) brightness(110%) hue-rotate(195deg)
    // filter: contrast(60%) sepia(100%) saturate(300%) brightness(100%) hue-rotate(195deg)
  ),
  blue: (
    background: #F5FFFD,
    color: #1639DE,
    color2: #1639DE50,
    color3: #1639DE90,
    secondary: #627DFF,
    line: #C7D1FF,
    header1: #EFF4FB,
    header2: #F2FDFF,
    sel: #859CED,
    light: #ADFFFA,
    filter: contrast(60%) sepia(100%) saturate(300%) brightness(100%) hue-rotate(185deg)
  ),
  gray: (
    background: #FAFAFA,
    color: #454545,
    color2: #45454550,
    color3: #45454590,
    secondary: #B5B5B5,
    line: #DDDDDD,
    header1: #EEEEEE,
    header2: #EDEDED,
    sel: #454545,
    light: #E5E5E5,
    filter: contrast(60%) sepia(90%) saturate(30%) brightness(100%) hue-rotate(185deg)
  ),
  brown: (
    background: #FFFDF5,
    color: #534200,
    color2: #53420050,
    color3: #53420090,
    secondary: #B69D3B,
    line: #E3D8AB,
    header1: #FBF8EF,
    header2: #FFFAF2,
    sel: #A99F7A,
    intro: #F66F0D,
    light: #FFE3AD,
    filter: contrast(60%) sepia(100%) saturate(60%) brightness(100%) hue-rotate(5deg)
  ),
  orange: (
    background: #FFFAF5,
    color: #D3470B,
    color2: #D3470B50,
    color3: #D3470B90,
    secondary: #E9882F,
    line: #FFE5C7,
    header1: #FFF2F2,
    header2: #FFF8EE,
    sel: #E9882F,
    light: #F8DCB2,
    filter: contrast(50%) sepia(100%) saturate(150%) hue-rotate(335deg)
  ),
);

$theme-map: null;

@function t($key) {
  @return map-get($theme-map, $key);
}

@each $theme, $map in $themes {
  $theme-map: map-get($themes, $theme) !global;
  $toolback: color.scale(t('color'), $lightness: 88%);
  $tooledge: color.change(t('sel'), $lightness: 65%);

  /* Theme container */
  body.mvt_#{"" + $theme} {
    background: t('background');
    color: t('color');
    --mvt-toolback: #{$toolback};
    --mvt-halfcolor: #{color.change(t('color'), $alpha: 0.5)};

    /* Reuseable theme classes */
    div.button, .mvt_back, ul.menu {
      background-color: t('background');
    }

    .mvt_shadow {
      box-shadow: 2px 2px 4px t('line');
    }

    .mvt_bline {
      border-bottom: solid 1px t('line');
    }

    .mvt_rline {
      border-right: solid 1px t('line');
    }

    .mvt_tline {
      border-top: solid 1px t('line');
    }

    .mvt_frame {
      border: solid 1px t('color');
    }

    .mvt_iframe {
      box-shadow: 0px 0px 8px t('line');
    }

    .mvt_fill {
      background: #{$toolback}; 
    }

    a.mvt_link, .mvt_secondary {
      color: t('secondary');

      &.active {
        color: t('color');
      }
    }

    a.icon {
      color: t('secondary');
      svg {
        fill: t('secondary');
      }
    }

    svg.mvt {
      stroke: t('color');
      fill: t('background');
    }

    /* Main structural theme styles */
    main > header, nav > header {
      background: t('header1');
    }

    article > header, #intro > header, #intro > footer, #embed > header {
      background-image: linear-gradient(to right, t('header1'), t('header2'));
    }

    nav {
      .sel {
        a {
          color: t('color');
          svg {
            fill: t('color');
          }
        }
      }
    }

    button.standard, a.button, ul.menu > li {
      &:hover {
        background-color: color.change(t('color'), $lightness: 90%);
      }
    }

    ul.opts > li {
      &:hover {
        background-color: color.change(t('color'), $lightness: 95%);
      }

      &.sel, &.sel:hover {
        background-color: color.change(t('color'), $lightness: 90%);
      }
    }

    .mvt_light {
      font-family: 'Roboto Slab';
      font-weight: bold;
      border: solid 1px t('color2');
      color: t('color');
      background-color: t('background');
      padding: 6px 14px;

      &::placeholder {
        color: inherit;
        opacity: 0.5;
      }
    }

    .mvt_dark {
      color: t('color');
      font-family: 'Red Rose';
      padding: 6px 14px;
      border: solid 1px t('color');
      background: t('background');
      font-size: 1.1rem;

      &::placeholder {
        color: inherit;
      }
    }

    button.mvt_dark {
      background-color: t('color');
      color: t('light');
      border-left: solid 2px t('light');
      border-top: solid 2px t('light');
      border-right: solid 2px t('color');
      border-bottom: solid 2px t('color');
    }

    .ghost {
      background-color: t('background');
      border-color: t('color3');
      box-shadow: 0px 0px 1px 1px #183A6C30;

      p {
        color: t('color2');
      }
    }

    .selection {
      border-color: #{$tooledge};

      & > svg {
        stroke: #{$tooledge};
      }
    }

    #theme-#{"" + $theme} > svg {
      border-bottom: solid 1px t('sel');
    }

    #form, #welcome {
      input.single {
        color: t('color');
        background-color: $toolback;
      }

      button {
        border: solid 1px t('color');
        color: t('color');
      }
    }

    .mvlogo {
      background: url(../images/mvlogo-#{"" + $theme}.png) no-repeat;
    }

    #intro {
      #pc {
        background: url(../images/landing-pc-#{"" + $theme}.png) no-repeat;
      }

      #title {
        background: url(../images/landing-title-#{"" + $theme}.png) no-repeat;
      }

      .detail > h2 {
        background: url(../images/landing-subs-#{"" + $theme}.png) no-repeat;
      }
    }

    /* Retina-specific stuff here */
    @media 
    (-webkit-min-device-pixel-ratio: 2), 
    (min-device-pixel-ratio: 2), 
    (min-resolution: 192dpi) { 
      .mvlogo {
        background-image: url(../images/mvlogo-#{"" + $theme}@2x.png);
        background-size: 163px 31px;
      }

      #intro {
        #pc {
          background-image: url(../images/landing-pc-#{"" + $theme}@2x.png);
          background-size: 100%;
        }

        #title {
          background-image: url(../images/landing-title-#{"" + $theme}@2x.png);
          background-size: 100%;
        }

        .detail > h2 {
          background-image: url(../images/landing-subs-#{"" + $theme}@2x.png);
          background-size: 799px 551px;
        }
      }
    }
  }
}
