@import './colors.scss';

:root {
  --mvpat-confetti: url(../patterns/1/pattern-confetti.svg);
  --mvpat-basket: url(../patterns/1/pattern-basket.svg);
  --mvpat-polka2: url(../patterns/1/pattern-polka2.svg);
  --mvpat-square: url(../patterns/1/pattern-square.svg);
  --mvpat-wave: url(../patterns/1/pattern-wave.svg);
  --mvpat-dense: url(../patterns/1/pattern-dense.svg);
  --mvpat-polka: url(../patterns/1/pattern-polka.svg);
  --mvpat-striped2: url(../patterns/1/pattern-striped2.svg);
  --mvpat-weave2: url(../patterns/1/pattern-weave2.svg);
  --mvpat-diamond: url(../patterns/1/pattern-diamond.svg);
  --mvpat-scales2: url(../patterns/1/pattern-scales2.svg);
  --mvpat-striped3: url(../patterns/1/pattern-striped3.svg);
  --mvpat-weave: url(../patterns/1/pattern-weave.svg);
  --mvpat-fleurs: url(../patterns/1/pattern-fleurs.svg);
  --mvpat-scales: url(../patterns/1/pattern-scales.svg);
  --mvpat-striped: url(../patterns/1/pattern-striped.svg);
  --mvpat-zig: url(../patterns/1/pattern-zig.svg);
  --mvpat-hatch: url(../patterns/1/pattern-hatch.svg);
  --mvpat-square2: url(../patterns/1/pattern-square2.svg);
  --mvpat-tri: url(../patterns/1/pattern-tri.svg);
}

//
// General layout (mobile first)
//
body {
  font-family: "Red Rose", Arial, sans-serif;
  font-size: 11px;
  margin: 0;
  padding: 0;
  font-size: 1rem;
  -webkit-text-size-adjust: 100%;
}

main {
  & > nav {
    text-align: center;

    ul {
      list-style: none;
      margin: 0;
      padding: 0 20px;
      overflow-x: auto;
      overflow-y: hidden;
      -ms-overflow-style: -ms-autohiding-scrollbar;
      -webkit-overflow-scrolling: touch;
      white-space: nowrap;

      &::-webkit-scrollbar {
        display: none;
      }

      a, u {
        text-decoration: none;
      }

      a:visited, a:active {
        color: unset;
      }

      span {
        display: inline-block;
        width: 32px;
      }
    }

    li {
      display: inline-block;
      margin-right: 10px;

      &.rule {
        margin: 14px;
      }
    }
  }
}

h3 {
  margin: 12px 0;
}

h4 {
  font-size: 12px;
  margin: 12px 0 18px 0;
}

main > header,
nav > header {
  text-align: left;
  height: 60px;

  .mvlogo {
    display: inline-block;
    height: 32px;
    width: 166px;
    margin: 14px 36px;
    cursor: pointer;
  }
}

header {
  font-weight: bold;

  nav {
    padding: 20px 30px;
    display: none;
    font-weight: bold;
  }
}

article {
  & > header {
    display: none;
  }

  & > section {
    padding: 30px 30px 80px 30px;
    /* padding-top: calc(100vw / 20); */
    text-align: center;

    & > div {
      text-align: left;
      display: inline-block;
    }
  }
}

header > nav svg,
footer svg {
  vertical-align: middle;
}

footer {
  display: none;

  & > div {
    padding: 18px 30px;
  }

  p {
    margin: 0;
    
    & > a {
      text-decoration: none;
    }
  }

  svg {
    padding-bottom: 3px;
    border: solid 1px transparent;
  }

  span {
    width: 80px;
    display: inline-block;
  }
}


//
// Widescreen CSS layout changes
//
@media(min-width: 1100px) {
  main > nav {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    text-align: left;
    width: 260px;
    height: 100vh;

    & > header {
      flex: 0 1 auto;
    }

    & > ul {
      flex: 1 1 auto;
      margin: 32px 0;

      li {
        display: list-item;

        p {
          margin: 0 20px;

          a {
            display: block !important;
          }
        }
      }
    }

    & > footer {
      display: block;
      flex: 0 1 40px;
      padding: 18px 30px;
    }
  }

  main > article {
    margin-left: 260px;

    & > header {
      display: block;
      text-align: left;
      width: auto;

      & > nav {
        display: block;
      }
    }
  }
}

//
// Common components
//
.mario {
  display: grid;

  & > div {
    margin: 12px;

    .content {
      margin: 0;
      text-align: center;
    }
  }

  .extended > .drawer {
    position: absolute;
    margin-left: -200px;
    display: none;
    z-index: 2;

    &.open {
      display: block;
    }

    & > svg {
      margin-top: 6px;
      padding-left: 220px;
    }

    & > .mario {
      margin-top: -5px;
      border-radius: 6px;
      background-color: var(--mvt-toolback);
    }
  }
}

.col2 {
  display: grid;
  grid-column-gap: 24px;
  grid-template-columns: repeat(2, 1fr);
}

.col3 {
  display: grid;
  grid-column-gap: 24px;
  grid-template-columns: repeat(3, 1fr);
}

button.standard, a.button {
  display: inline-block;
  font-weight: bold;
  padding: 8px 14px;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  border: none;
  border-radius: 7px;
  background: none;
  color: inherit;
  white-space: nowrap;
  font-family: inherit;

  &.pressed {
    & > .base {
      color: transparent;
      & > svg {
        fill: transparent;
      }
    }
    & > .flash {
      display: inline-block;
    }
  }

  .drop {
    padding: 10px;
    margin-left: 18px;
  }

  .flash {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    margin: 6px 12px;
    padding: 2px;
  }

  .base > b {
    padding: 0 6px;
  }

  svg {
    vertical-align: middle;
  }
}

#app > .banner,
#app > div > .banner {
  position: absolute;
  right: 40px;
  top: 12px;
  z-index: 3;
}

#app > div > .banner {
  top: 19px;
}

ul.menu {
  list-style: none;
  background-color: var(--mvt-toolback);
  border-radius: 6px;
  overflow: hidden;
  padding: 0;
  margin: 0;
  display: none;

  &.open {
    display: block;
  }

  & > li {
    padding: 8px 12px;
    font-weight: normal;

    a {
      text-decoration: none;

      svg {
        vertical-align: middle;
        margin: 0 4px 0 2px; 
      }
    }
  }
}

ul.tabs {
  padding: 10px 0;
  list-style: none;

  & > li {
    display: inline-block;
    margin-right: 60px;
    font-size: 1.2rem;

    & > a {
      text-decoration: none;
    }
  }
}

#editor {
  background-image: url('../images/wave-bg.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  .main {
    max-width: 1100px;
    margin: 0 auto;

    #banner {
      margin: 70px 0 30px 0;
    }
  }
}

#composer {
  width: 784px;

  & > div {
    input {
      font-size: 2rem;
      box-sizing: border-box;
      padding: 0px;
      color: inherit;
      font-family: "Red Rose", Arial, sans-serif;
    }
  }

  .col2 {
    margin: 50px 14px 50px 0;

    .meta {
      margin-top: 10px;
      width: 440px;
    }
  }

  .mario {
    & > div {
      cursor: pointer;
      padding-bottom: 10px;

      & > .box {
        opacity: 0.3;

        &:hover {
          opacity: 1.0;
        }
      }

      &.sel {
        & > .box {
          opacity: 1.0;
        }

        border-bottom: solid 2px;
      }
    }
  }

  .compose {
    background-color: transparent;
    outline: 0;
    border: none;
    width: 100%;

    &::placeholder {
      color: inherit;
      opacity: 0.5;
    }
  }

  div.compose-frame {
    position: relative;
    margin-bottom: 24px;
    overflow: hidden;

    & > img {
      position: absolute;
      right: 0px;
      width: 16px;
      padding: 6px;
      cursor: pointer;
      z-index: 2;
    }
  }

  div.compose {
    z-index: 1;

    p {
      margin: 0;
      margin-bottom: 12px;
    }

    p:last-of-type,
    &:last-of-type {
      margin-bottom: 0;
    }

    .placeholder {
      opacity: 0.5;
    }
  }
}

/* base styles for post layout */
/* frame styles (mv_fXX) are laid over this */
.box {
  position: relative;
  font-size: 1.4rem;

  .title {
    display: inline-block;
    position: relative;
    margin-top: 16px;
    margin-left: -24px;
    vertical-align: top;

    h3 {
      margin: 0px;
      padding: 2px 8px;
      font-size: 1.2rem;

      a {
        text-decoration: none;
      }
    }
  }

  div.box-fill,
  div.box-fill > div,
  div.box-shadow,
  div.box-shadow > div,
  div.box-highlight,
  div.box-highlight > div,
  div.title-shadow,
  div.title-shadow > div,
  div.title-highlight,
  div.title-highlight > div,
  div.title-fill,
  div.title-fill > div {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    transition: all 0.5s;
  }

  div.box-fill,
  div.title-fill {
    overflow: hidden;
  }

  div.box-shadow,
  div.title-shadow {
    top: 4px;
    left: 4px;
  }

  div.title-shadow {
    clip: rect(-20px, 13px, 400px, -20px);
  }

  div.title-highlight {
    clip: rect(-20px, 21px, 400px, -20px);
  }

  div.box-highlight,
  div.title-highlight {
    top: -4px;
    left: -4px;
  }

  div.box-content,
  div.title-content {
    position: relative;
    margin: 0;
    padding: 6px;
  }

  div.title-content {
    padding: 6px 10px;

    img {
      margin-right: 14px;
      vertical-align: middle;
      max-height: 24px;
    }

    span.author {
      vertical-align: middle;
    }
  }

  div.box-content {
    font-size: 1rem;
    line-height: 140%;

    & > .box-block {
      & > .box-element {
        margin: 0.3em 0 0.7em 0;
        overflow: hidden;
        overflow-wrap: break-word;

        & > *:first-child {
          margin-top: 0;
        }

        & > *:last-child {
          margin-bottom: 0;
        }
      }

      & > .box-element-html {
        box-sizing: border-box;
        padding: 6px 8px;
        line-height: 140%;
        
        a {
          color: unset;
        }
      }

      & > .box-element-img > img {
        display: block;
      }
    }
  }
}

/* condensed name tag style */
.mv_fl1 {
  & > div.box-content {
    & > .box-block {
      margin-left: 32px;
    }

    & > .title {
      float: left;
      margin-top: 6px;

      & > div.title-content {
        padding: 0;
        overflow: hidden;

        & > h3 {
          padding: 0;

          img {
            max-height: 48px;
            margin: 0;
          }

          span.author {
            display: none;
          }
        }
      }
    }
  }
}

/* comic log style */
.mv_l1 {
  & > .box-block {
    position: relative;
    margin: 8px 32px;
    
    & > .box-element {
      position: absolute;
      margin: 0;
    }
  }
}

.header {
  font-size: 0.9em;
  margin-top: 30px;
  line-height: 140%;
  max-width: 760px;

  & > h1 {
    font-size: 1.4rem;
    margin: 10px 0px;
  }

  .summary {
    margin: 14px 0 20px 0;
    text-shadow: none;
    font-size: 0.9em;

    .at {
      opacity: 0.6;
    }

    & > *, & > div.p-summary > * {
      display: inline;
    }
  }
}

.post-body {
  div.box-content {
    min-height: 64px;
  }

  div.box-block {
    /* width: max(min(70vw, 700px), 220px); */
    width: 700px;
  }

  & > .box:nth-child(even) {
    display: inline-block;
    margin-left: 34px;
    margin-right: -34px;
    margin-top: -10px;
    margin-bottom: -10px;
  }
}

.post-preview {
  transition: all 0.5s;
  position: relative;

  & > header {
    cursor: pointer;
  }

  .post-body {
    position: relative;
    z-index: 2;
  }

  & > .post > a.u-url, & > a.u-url {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .box {
    margin: 20px 0 50px 0;

    div.box-content {
      min-height: 70px;

      & > .content,
      & > .e-content {
        width: 700px;
        // max-height: 200px;
        // margin-bottom: 0px;

        // &:last-child {
        //   padding-bottom: 16px;
        // }
      }
    }
  }

  &.post-draft {
    .header {
      margin-bottom: 50px;
    }
    .box > div.box-content > .content {
      height: 70px;
    }
  }

  .more {
    position: absolute;
    margin-top: -66px;
    width: 100%;
    text-align: center;

    & > .button {
      display: inline-block;
      width: 140px;
      border: solid 2px;
      padding: 4px;
      border-radius: 6px;
      margin: 0 10px;
      text-align: center;
      cursor: pointer;
    }
  }

  .actions {
    float: right;
    display: block;
    text-align: right;

    & > a {
      padding: 10px;
    }

    .menu {
      position: absolute;
      z-index: 2;
      width: 160px;
      right: 0;
    }
  }
}


.feed {
  .group {
    float: right;
    position: relative;
    z-index: 1;

    & > a + a:before {
      content: ", ";
    }
  }

  .paginate {
    text-align: center;
    padding: 32px;
  }

  .group > a,
  .paginate > a {
    font-weight: bold;
    text-decoration: none;
  }
}

#home, #group {
  & > h1 {
    display: none;
  }

  & > h2 {
    font-size: 0.8rem;
    margin: 0 0 40px;
    padding-bottom: 16px;
  }
}

#canvas {
  max-width: 950px;
  margin: 0 auto;
  position: relative;
}

#right {
  float: right;
  margin-top: 0px;
  margin-left: 20px;
  max-width: 480px;
}

#toolbars {
  float: left;
  margin: 0px 40px 0 0;
  font-size: 9pt;
  text-align: center;

  .options, .details {
    background-color: $tool-fill;
    border: solid 1px $tool-border;
    display: inline-block;
    color: $tool-text;
    border-radius: 4px;
    margin: 0 auto;
    margin-bottom: 12px;
    text-align: left;

    div.sep {
      display: inline-block;
      border-left: solid 1px #98989866;
      padding: 2px;
    }

    div.sep:first-child {
      border: none;
    }
  }

  button {
    cursor: pointer;
    color: $tool-text;
    font-family: 'Roboto Slab';
    font-weight: bold;
    font-size: 9pt;
    border: none;
    background: none;
    padding: 6px 8px;
    border-radius: 2px;
    height: 32px;

    & > img {
      width: 16px;
      vertical-align: middle;
      margin-right: 8px;
      padding-bottom: 2px;
    }

    &:focus {
      outline: 0;
    }

    &:hover {
      background-color: $tool-hover;
    }

    &.sel {
      background-color: $tool-choice;
    }
  }

  .tab {
    padding: 6px;
    border-bottom: solid 0.5px $tool-border;
  }

  .pane {
    padding-bottom: 12px;

    .collapse > p {
      background: #EBF9FE;
      text-align: center;
      padding: 4px;
      margin: 0 0 -12px 0;
      border-radius: 4px;

      a {
        text-decoration: none;
      }

      a.bar:after,
      a.bar:before {
        display: inline-block;
        content: "";
        width: 18px;
        height: 12px;
        margin: 0 14px;
      }
    }

    .shown {
      a.bar:after,
      a.bar:before {
        background: url(../icons/arrow_collapse.svg) no-repeat;
      }
    }

    .hidden {
      a.bar:after,
      a.bar:before {
        background: url(../icons/arrow_expand.svg) no-repeat;
      }
    }
  }

  .toolbar {
    padding: 8px 15px;

    & > p:first-child {
      margin: 4px 0px;
    }

    p {
      &.value, &.fmt {
        display: inline-block;

        input {
          text-align: right;
          border: none;
          background: none;
          outline: 0;
          color: $tool-text;
          font-size: 9pt;
          font-family: "Red Rose", Arial, sans-serif;
        }
      }

      &.value {
        float: right;
      }
    }

    .wide {
      display: grid;
      grid-column-gap: 6px;
      grid-template-columns: repeat(4, 1fr);

      .opt {
        cursor: pointer;
        border: solid 1px $tool-border-light;
        border-radius: 2px;
        padding: 6px;
        text-align: center;
        height: 16px;

        & > img {
          vertical-align: middle;
        }

        &.sel {
          background-color: $tool-choice;
          border-color: $tool-border;
        }
      }
    }

    .palette {
      display: grid;
      grid-column-gap: 3px;
      grid-row-gap: 3px;
      grid-template-columns: repeat(6, 1fr);

      .opt {
        width: 34px;
        height: 34px;
        border-style: solid;
        border-width: 1px;
        border-color: white;
        border-radius: 4px;
        cursor: pointer;
        border-color: #2140e040;

        &.sel {
          border-color: $tool-border;
        }
      }
    }

    .shadow {
      & > .opt {
        border-color: transparent;
        background-repeat: no-repeat;
      }

      & > .sel > img {
        width: 10px;
        padding: 11px;
      }
    }
  }

  .borders {
    display: grid;
    grid-template-columns: repeat(10, 1fr);

    & > div > a {
      width: 20px;
      display: inline-block;
      text-align: center;
      height: 58px;
      padding-top: 11px;

      &.sel {
        background-image: url(../icons/border_selector_both.svg);
        background-repeat: no-repeat;
        background-position: 5px 1px;
      }
    }
  }

  .slider {
    position: relative;

    .grade {
      width: 230px;
    }

    .knob {
      cursor: pointer;
      -webkit-appearance: none;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      padding: 2px;
      background: transparent;
      font-size: 0.6rem;

      &:focus {
        outline: none;
      }

      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        border: solid 2px $tool-border;
        width: 12px;
        height: 12px;
        border-radius: 50%;
      }
    }
  }

  .font-selector {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    min-width: 380px;

    button {
      padding: 8px 12px;
      margin: 4px;
      text-align: center;

      &:focus {
        outline: 0;
      }

      &.sel {
        background-color: $tool-choice;
      }
    }
  }
}

#settings {
  margin-bottom: 48px;
}

#toolbars, #settings, #profile > .h-card {
  label {
    display: block;
    font-size: 11px;
  }

  button.standard {
    margin-top: 12px;
  }

  button.standard, a.button {
    border: solid 1px;
    opacity: 0.7;
  }

  h3 {
    font-size: 14px;
    text-align: right;
    margin: 0;
    padding: 6px 0;
  }

  .col2 {
    grid-template-columns: 172px auto;
  }

  .avatar {
    display: inline-block;
    text-align: center;

    img {
      max-width: 140px;
      max-height: 140px;
      padding: 0 6px;
    }

    a {
      font-size: 0.7rem;
      text-decoration: none;
      color: inherit;
    }
    
    & > p {
      text-align: center;
      width: 108px;
    }
  }

  .field {
    margin-top: 12px;
    margin-left: 10px;

    p {
      margin: 0;
      font-size: 1.1rem;

      a {
        text-decoration: none;
        color: inherit;
      }
    }

    input, h1 {
      font-size: 1.6rem;
      box-sizing: border-box;
      border: none;
      background: none;
      color: inherit;
    }

    .handle, input, h1 {
      font-family: "Contrail One", Arial, sans-serif;
      font-weight: bold;
    }

    .handle {
      font-size: 1rem;
      opacity: 0.3;
    }
  }

  .frames {
    margin-top: 20px;
    width: 500px;
  }
}

#toolbars, #settings {
  .mario {
    & > div {
      cursor: pointer;
    }
  }
}

#picker {
  margin-top: 20px;

  .IroBoxBg {
    rx: 2;
    ry: 2;
  }
}

#assets {
  display: none;
}

#form, #welcome .fields {
  margin-top: 1em;
  max-width: 320px;
  font-family: 'Roboto Slab';

  label {
    display: block;
    font-weight: bold;
    font-size: 0.8rem;
  }

  input.single, button {
    font-family: inherit;
    font-weight: bold;
    margin: 8px 0;
    border: none;
    border-radius: 6px;
    outline: 0;
  }

  input.single {
    padding: 8px 6px;
    width: 100%;
  }

  button {
    background: none;
    padding: 8px 26px;
    cursor: pointer;
  }
}

#welcome {
  max-width: min(100vw, 400px);
  .post {
    margin: 20px 0;
  }
}

#about {
  max-width: 700px;
  .post {
    margin: 40px 20px;
  }
  .mv_lvl {
    position: absolute;
    right: -16px;
    bottom: 16px;

    & > .mv_att {
      position: absolute;
      left: 50px;
      top: -60px;
      width: 700px;
      display: none;
    }

    & > a.arrow {
      color: white;
      text-decoration: none;
      font-weight: bold;
      padding: 0 12px;
      border-radius: 6px;

      span:last-child {
        display: none;
      }

      &.glitchyowl {
        background: #471AFE;
        box-shadow: -2px 2px 0 0 #E9E9FF;

        &:hover {
          color: #471AFE;
          background: #DDCCEE;
        }
      }

      &.kicks {
        color: black;
        background: #BBF4E8;
        border: solid 1px #C629C9;
        box-shadow: -2px 2px 0 0 #E9E9FF;

        &:hover {
          color: #BBF4E8;
          background: #C629C9;
        }
      }
    }

    &.show {
      & > .mv_att {
        display: block;
      }

      & > a.arrow {
        span:first-child {
          display: none;
        }
        span:last-child {
          display: inline;
        }
      }
    }
  }
}

#intro {
  header > ul.links {
    float: right;
    list-style: none;
    margin-right: 50px;

    & > li {
      display: inline-block;
      margin-left: 90px;

      & > a {
        font-weight: normal;
        text-decoration: none;
      }
    }
  }

  & > .center {
    text-align: center;

    & > div {
      margin: auto;

      &.signup {
        margin-top: 100px;
        margin-bottom: 140px;

        button {
          cursor: pointer;
        }
      }
    }
  }

  .column {
    text-align: left;
    width: 800px;

    & > .center {
      margin: 60px auto 140px auto;
      text-align: center;

      & > .mario {
        width: 540px;
        margin: 40px auto;
        grid-column-gap: 18px;
      }

      & > .post {
        margin: auto;
        width: 600px;
        text-align: left;
      }
    }
  }

  #pc {
    margin-top: 100px;
    width: 718px;
    height: 800px;
    background-size: 100%;
  }

  #title {
    margin-top: -90px;
    width: 934px;
    height: 213px;
    background-size: 100%;

    & > * {
      display: none;
    }
  }

  .mood > p {
    font-size: 1.4em;
    margin: 4px;

    &.hi {
      font-weight: bold;
      margin: 1.4em;
    }
  }

  .detail {
    margin-top: 140px;

    & > h2 {
      background-size: 100%;

      & > span {
        display: none;
      }
    }

    & > p {
      width: 600px;
    }
  }

  #ready {
    & > h2 {
      background-position: -2px -14px;
      width: 410px;
      height: 50px;
    }

    & > p {
      width: 560px;
    }
  }

  #pssst-boxes {
    float: right;

    & > .post {
      margin-bottom: 40px;

      h3 {
        font-size: 0.8em;
        font-weight: normal;
      }
    }
  }

  #pssst {
    margin-bottom: 1000px;

    & > h2 {
      background-position: -2px -92px;
      width: 258px;
      height: 180px;
    }

    & > p {
      width: 340px;
    }
  }

  #homemade > h2 {
    background-position: -2px -290px;
    width: 628px;
    height: 102px;
  }

  .smolview {
    margin: 80px 0 280px 0;

    h3 {
      font-weight: normal; 

      a {
        padding-bottom: 4px;
      }
    }

    li.sel h3 {
      font-weight: bold;
    }

    a {
      color: inherit;
      text-decoration: none;
    }

    .opts {
      margin: 0 -20px;
      list-style: none;
      padding: 0;
      width: 300px;

      & > li {
        border-radius: 12px;
        padding: 1px 20px;
        margin-bottom: 12px;
      }
    }
  }

  .mvt_iframe {
    float: right;
    border-radius: 12px;
  }

  #fullcolor > h2 {
    background-position: -2px -425px;
    width: 359px;
    height: 47px;
  }

  #blogchat > h2 {
    background-position: -2px -497px;
    width: 472px;
    height: 45px;

    img {
      float: right;
      margin-right: -120px;
      margin-top: -30px;
    }
  }

  & > footer {
    display: block;

    & > div.col3 {
      margin: auto;
      padding: 40px 0;
      width: 800px;

      p {
        font-weight: bold;
      }

      ul {
        margin: 0;
        list-style: none;

        li {
          margin-bottom: 6px;

          a {
            text-decoration: none;
          }
        }
      }
    }
  }
}

#welcome, #about {
  h1 {
    line-height: 140%;
  }

  .post {
    display: table;

    div.box-content {
      padding: 0 6px;

      & > .title {
        margin-right: 36px;
        margin-top: 8px;
      }
    }

    &:nth-child(even) {
      margin-left: 34px;
      margin-right: -34px;
      margin-top: -10px;
      margin-bottom: -10px;
    }
  }

  .avatar {
    text-align: center;

    img {
      width: 96px;
      border: solid 1px;
    }

    a {
      text-decoration: none;
      color: inherit;
    }
  }

  .fields {
    margin: 40px 12px;
  }
}

.grid-4 {
  grid-template-columns: repeat(4, 1fr);
}

.grid-5 {
  grid-template-columns: repeat(5, 1fr);
}

.grid-6 {
  grid-template-columns: repeat(6, 1fr);
}

.subpane {
  margin: 60px auto;
  margin-top: 120px;
  width: 700px;
  position: relative;

  & > .kaylen {
    position: absolute;
    top: -50px;
    left: 0px;
  }

  & > .frame {
    position: relative;

    & > .fill {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 4px;
      left: 4px;
    }

    & > .content {
      position: relative;
      padding: 32px 24px;

      & > h3 {
        font-size: 1.4rem;
      }

      button {
        margin-left: 12px;
        cursor: pointer;
      }
    }
  }
}

#profile {
  & > .h-card {
    display: block;
    width: 700px;
  }

  p.e-content {
    margin: 3px;
  }
}

#embed {
  & > .post {
    margin: 60px 120px;
  }
}

